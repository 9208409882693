import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import "../css/ea.css";
import noprofile from "../assets/img/blankprofile.webp";
import PerfilCompletoColab from './PerfilCompletoColab';
import LoadingSpinner from './LoadingSpinner'; // Certifique-se de que o spinner está corretamente importado

const ListaColaboradores = () => {
  const [colaboradores, setColaboradores] = useState([]);
  const [loading, setLoading] = useState(true); // Estado para controle do carregamento
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedColaborador, setSelectedColaborador] = useState(null);
  const [perfilCompleto, setPerfilCompleto] = useState(null);

  async function fetchColaboradores() {
    try {
      const empresaId = Cookies.get("empresaId");
      console.log('Empresa ID:', empresaId);
  
      const token = Cookies.get("token"); // Verifica o token de autenticação
      if (!token) {
        throw new Error("Usuário não autenticado");
      }

      const response = await axios.get(`${process.env.REACT_APP_API_URL}/funcionarios`, {
        params: { empresaId: empresaId },
        headers: { Authorization: `Bearer ${token}` },
      });
  
      setColaboradores(response.data);
    } catch (error) {
      console.error("Erro ao buscar colaboradores:", error);
    } finally {
      setLoading(false); // Define loading como falso após o carregamento
    }
  }

  useEffect(() => {
    fetchColaboradores();
  }, []);

  const handleExcluir = async (colaborador) => {
    setSelectedColaborador(colaborador);
    setShowConfirmation(true);
  };
  
  const confirmarExclusao = async () => {
    if (!selectedColaborador || !selectedColaborador.IdFuncionario) {
      console.error("ID do colaborador não definido.");
      setShowConfirmation(false);
      return;
    }
  
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/funcionarios/${selectedColaborador.IdFuncionario}`);
      setColaboradores(colaboradores.filter(col => col.IdFuncionario !== selectedColaborador.IdFuncionario));
      setSelectedColaborador(null); // Limpar o colaborador selecionado
    } catch (error) {
      console.error('Erro ao excluir colaborador:', error);
    } finally {
      setShowConfirmation(false);
    }
  };

  const handleVerMais = (colaborador) => {
    setPerfilCompleto(colaborador);
  };

  return (
    <div className="flexcolumn">
      <div className="tandline">
        <h2>Colaboradores</h2>
        <div className="line300"></div>
      </div>

      {/* Exibir o spinner enquanto o estado de loading for verdadeiro */}
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          {showConfirmation && (
            <div className="modal-background">
              <div className="modal">
                <h3>Confirmar Exclusão</h3>
                <p>
                  Deseja realmente excluir o colaborador {selectedColaborador.nome}?
                </p>
                <div className="modal-buttons">
                  <button onClick={confirmarExclusao}>Sim</button>
                  <button onClick={() => setShowConfirmation(false)}>Cancelar</button>
                </div>
              </div>
            </div>
          )}

          {perfilCompleto && (
            <PerfilCompletoColab
              colaborador={perfilCompleto}
              onClose={() => setPerfilCompleto(null)}
              onExcluirColaborador={(idExcluido) => {
                setColaboradores(colaboradores.filter(col => col.IdFuncionario !== idExcluido));
              }}
            />
          )}

          {!perfilCompleto && (
            <div className="flexcolumn listacolab">
              {colaboradores.map((colaborador, index) => (
                <div key={index} className="flexrow">
                  <div className="imagepr">
                    <div className="profile-pink">
                      <div className="profile-pink-detalhe">
                        <span className="ranking-nv">{index + 1}</span>
                      </div>
                      <img
                        className="wid60 border100"
                        src={colaborador.fotoPerfilColab ? `${process.env.REACT_APP_API_URL}/${colaborador.fotoPerfilColab}` : noprofile}
                        alt="Imagem de Perfil"
                      />
                    </div>
                  </div>
                  <div className="flexrow">
                    <span className="titpostr">
                      {colaborador.nome}
                    </span>

                    <div className="btn-black" onClick={() => handleExcluir(colaborador)}>
                      <span>Excluir</span>
                    </div>
                    <div className="btn-black" onClick={() => handleVerMais(colaborador)}>
                      <span>Ver Mais</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ListaColaboradores;
