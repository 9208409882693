import React, { useState, useEffect } from "react";
import axios from 'axios';
import "../css/ea.css";
import noprofile from "../assets/img/blankprofile.webp";
import ListaColaboradores from "./ListaColaboradores";
import CadastrarColab from "./CadastrarColab";
import PerfilCompletoColab from "./PerfilCompletoColab";
import Ranking from "./Ranking";

const Colaboradores = () => {
  // Aqui estamos inicializando o estado colaboradores como um array vazio
  const [colaboradores, setColaboradores] = useState([]); 
  const [selectedComponent, setSelectedComponent] = useState(null);
  const [selectedButton, setSelectedButton] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState(null);

  useEffect(() => {
    setSelectedComponent("Colaboradores");
    setSelectedButton("Colaboradores");

    // Simulação de busca de colaboradores (pode ser substituído por uma chamada API)
    // Aqui você pode fazer uma requisição para buscar os colaboradores da API
    setColaboradores([
      { IdFuncionario: 1, nome: 'João', ups: 5, fotoPerfilColab: noprofile },
      { IdFuncionario: 2, nome: 'Maria', ups: 10, fotoPerfilColab: noprofile },
    ]);
  }, []);

  const handleButtonClick = (componentName) => {
    setSelectedComponent(componentName);
    setSelectedButton(componentName);
  };

  const handleVerMais = (colaborador) => {
    setPopupContent(colaborador);
    setShowPopup(true);
  };

  const renderSelectedComponent = () => {
    switch (selectedComponent) {
      case "Colaboradores":
        return <ListaColaboradores handleVerMais={handleVerMais} />;
      case "CadastrarColab":
        return <CadastrarColab />;
      case "Ranking":
        // Aqui estamos passando colaboradores || [] para garantir que seja um array
        return <Ranking colaboradores={colaboradores || []} />;
        
      default:
        return null;
    }
  };

  return (
    <div className="w100">
      <div className="column_mo direitaopcs">
        <div className="ranking-conteudos">
          {renderSelectedComponent()}
        </div>

        <div className="flexcolumn flexrow_mo m10_bt fix_b_centro">
          <div
            className={`btn   ${selectedButton === "Colaboradores" ? "selected" : ""}`}
            onClick={() => handleButtonClick("Colaboradores")}
          >
            <span>Colaboradores</span>
          </div>

          <div
            className={`btn   ${selectedButton === "CadastrarColab" ? "selected" : ""}`}
            onClick={() => handleButtonClick("CadastrarColab")}
          >
            <span>Cadastrar Novo</span>
          </div>
          <div
            className={`btn d_none   ${selectedButton === "Ranking" ? "selected" : ""}`}
            onClick={() => handleButtonClick("Ranking")}
          >
            <span>Ranking</span>
          </div>
        </div>
      </div>

      {showPopup && (
        <PerfilCompletoColab 
          colaborador={popupContent} 
          onClose={() => setShowPopup(false)} 
        />
      )}
    </div>
  );
};

export default Colaboradores;
