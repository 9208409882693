import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import Cookies from "js-cookie";
import "../css/ea.css";
import noprofile from "../assets/img/blankprofile.webp";
import more from "../assets/img/more.svg";

const PerfilCompletoEmpresa = ({ empresa, onClose }) => {
  const [empresaData, setEmpresaData] = useState({
    nome: empresa.nome,
    endereco: empresa.endereco,
    adm_nome: empresa.adm_nome,
    telefone: empresa.telefone,
    email: empresa.email,
    colaboradoresLimite: empresa.colaboradoresLimite,
  });
  const [isModified, setIsModified] = useState(false);
  const [erroMsg, setErroMsg] = useState("");

  const empresaId = empresa.empresaId;

  useEffect(() => {
    setEmpresaData({
      nome: empresa.nome,
      endereco: empresa.endereco,
      adm_nome: empresa.adm_nome,
      telefone: empresa.telefone,
      email: empresa.email,
      colaboradoresLimite: empresa.colaboradoresLimite,
    });
  }, [empresa]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEmpresaData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setIsModified(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    Object.keys(empresaData).forEach(key => {
      formDataToSend.append(key, empresaData[key]);
    });

    sendUpdate(formDataToSend);
  };

  const sendUpdate = async (formDataToSend) => {
    const token = Cookies.get("token");
    try {
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/changeempresa/${empresaId}`, formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`
        },
      });

      console.log("Empresa atualizada com sucesso:", response.data);

      // Atualizar cookies com os novos dados
      Object.keys(empresaData).forEach(key => {
        Cookies.set(`empresa${key.charAt(0).toUpperCase() + key.slice(1)}`, empresaData[key]);
      });

      setIsModified(false);
      setErroMsg('');
    } catch (error) {
      console.error("Erro ao atualizar dados da empresa:", error);
      setErroMsg("Erro ao atualizar dados. Por favor, tente novamente mais tarde.");
    }
  };

  const handleCancel = () => {
    if (isModified) {
      if (window.confirm("Você tem alterações não salvas. Deseja sair sem salvar?")) {
        onClose();
      }
    } else {
      onClose();
    }
  };

  return (
    <div className="flexcolumn head-profile">
      <div className="flexcolumn w30">
        <div className="logo-preview w30">
          <img
            className="border100"
            src={empresaData.logo ? `${process.env.REACT_APP_API_URL}/${empresaData.logo}` : noprofile}
            alt="Logo da Empresa"
          />
        </div>
      </div>
      <div className="gap5 flexcolumn ">
        <form onSubmit={handleSubmit} encType="multipart/form-data">
          <h3 className="bold">Editar Empresa</h3>
          <div className="form-fields-container">
            <div className="form-field">
              <label>Razão Social:</label>
              <input type="text" name="nome" value={empresaData.nome} onChange={handleChange} required />
            </div>
            <div className="form-field">
              <label>Endereço:</label>
              <input type="text" name="endereco" value={empresaData.endereco} onChange={handleChange} required />
            </div>
            <div className="form-field">
              <label>Nome do Administrador:</label>
              <input type="text" name="adm_nome" value={empresaData.adm_nome} onChange={handleChange} required />
            </div>
            <div className="form-field">
              <label>Telefone:</label>
              <input type="text" name="telefone" value={empresaData.telefone} onChange={handleChange} required />
            </div>
            <div className="form-field">
              <label>Email:</label>
              <input type="email" name="email" value={empresaData.email} onChange={handleChange} required />
            </div>
            <div className="form-field">
              <label>Limite de Colaboradores:</label>
              <input type="number" name="colaboradoresLimite" value={empresaData.colaboradoresLimite} onChange={handleChange} required />
            </div>
          </div>
          {erroMsg && <div className="error-msg">{erroMsg}</div>}
          
          <div className="flexrow m20top">
            <button type="button" className="btn-black large_button" onClick={handleCancel}>
              Fechar
            </button>
            <button
              type="submit"
              className={`btn-black-un large_button ${isModified ? "btn-modified" : ""}`}
              disabled={!isModified}
            >
              Salvar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

PerfilCompletoEmpresa.propTypes = {
  empresa: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PerfilCompletoEmpresa;
