// ./components/Ranking.js
import React from 'react';
import PerfilRankingMin from "./PerfilRankingMin";

const Ranking = ({ colaboradores = [] }) => {
  return (
    <div className="ranking">
      <div className="tandline m20top">
            <h2>UPS</h2>
            <div className="line300"></div>
          </div>
      <div className="ranking-list m10top flexcolumn">
        {colaboradores.length > 0 ? (
          colaboradores.map((colaborador, index) => (
            <PerfilRankingMin
              key={colaborador.IdFuncionario}
              ranking={index + 1}
              nome={colaborador.nome}
              ups={colaborador.ups}
              fotoPerfilColab={colaborador.fotoPerfilColab}
            />
          ))
        ) : (
          <p>Nenhum colaborador disponível no ranking.</p>
        )}
      </div>
    </div>
  );
};

export default Ranking;
