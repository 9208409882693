
import { useNavigate, Link, useLocation } from "react-router-dom";
import axios from "axios";
import "./css/ea.css";
import logoblack from "./logoblack.svg";
import Cookies from "js-cookie";
import { useUser } from "./UserContext";
import React, { useState, useEffect } from "react";  // Adiciona 'useEffect' à importação



const Home = () => {



  // Adicione esta constante no topo do seu componente
  const EMAIL_ESPECIAL = "adm@rise.app.br";
  const DASHBOARD_ESPECIAL = "/MainDashboard";
  const { setUser, setUserEmail } = useUser();
  const [formData, setFormData] = useState({
    email: "",
    senha: "",
  });
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const redirectUrl = new URLSearchParams(location.search).get('redirect') || '/';

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    console.log(`Input changed - ${name}: ${value}`);
  };

  const [rememberMe, setRememberMe] = useState(false);


  const handleCheckboxChange = (e) => {
    setRememberMe(e.target.checked);
  };

  useEffect(() => {
    const checkAuth = () => {
      const token = Cookies.get('token');
      const funcionarioId = Cookies.get('funcionarioId');
      const empresaId = Cookies.get('empresaId');
      const entityType = Cookies.get('entityType');
      console.log("Verificando autenticação:");
      console.log("funcionarioId from cookies:", funcionarioId);
      console.log("empresaId from cookies:", empresaId);
      console.log("entityType from cookies:", entityType);

      if (!token) {
        console.log("Nenhum token encontrado, usuário não autenticado.");
        return;
      }

      if (entityType === "empresa" && empresaId) {
        console.log("Navigating to /DashboardEmpresa");
        navigate("/DashboardEmpresa");
      } else if (entityType === "funcionario" && funcionarioId) {
        console.log("Navigating to /DashboardColab");
        navigate("/DashboardColab");
      } else {
        console.log("Nenhum usuário autenticado encontrado.");
      }
    };

    // Verificar imediatamente
    checkAuth();

    // Verificar novamente após 1 segundo
    const timer = setTimeout(checkAuth, 1000);

    return () => clearTimeout(timer);
  }, [navigate]);

  const [isLoading, setIsLoading] = useState(false); // Adicione o estado de carregamento
  const getEmpresaNomeEPlano = async (empresaId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/empresa_nome_plano/${empresaId}`);
      console.log("Dados da empresa recebidos:", response.data); // Adicione este log
      const { nome, plano_nome: plano_nome } = response.data;
  
      // Retorna tanto o nome da empresa quanto o nome do plano
      return { nome, plano_nome };
    } catch (error) {
      console.error('Erro ao obter os dados da empresa:', error);
      throw error;
    }
  };
  
  
  






  const fetchAndStoreFuncionarioData = async (email) => {
    try {
      const token = Cookies.get('token'); // Obter o token de autenticação
      if (!token) {
        throw new Error('Usuário não autenticado');
      }

      console.log(`Fetching funcionario data for email: ${email}`);
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/DadosColab`, {
        params: { email: email },
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const { IdFuncionario, nome, fotoPerfilColab, linkedin, cargo, dataAdmissao, idade, genero, empresaId, ups, emailConfirmed } = response.data;

      const updatedUser = {
        IdFuncionario,
        nome,
        fotoPerfilColab,
        linkedin,
        cargo,
        dataAdmissao: dataAdmissao ? dataAdmissao.slice(0, 10) : '',
        idade,
        genero,
        email,
        emailConfirmed
      };

      setUser(updatedUser);
      const { nome: empresaNome, plano_nome } = await getEmpresaNomeEPlano(empresaId);

      console.log("Nome da empresa:", empresaNome);
      console.log("Nome do plano:", plano_nome);
      


      Cookies.set('funcionarioId', IdFuncionario, { expires: rememberMe ? 365 : null });


      Cookies.set('funcionarioUPS', ups, { expires: rememberMe ? 365 : null });
      console.log(`UPS atualizado no cookie para o funcionário: ${ups}`);
      Cookies.set('funcionarioNome', nome, { expires: rememberMe ? 365 : null });
      Cookies.set('funcionarioFotoPerfilColab', fotoPerfilColab, { expires: rememberMe ? 365 : null });
      Cookies.set('funcionarioLinkedin', linkedin, { expires: rememberMe ? 365 : null });
      Cookies.set('funcionarioCargo', cargo, { expires: rememberMe ? 365 : null });
      Cookies.set('funcionarioDataAdmissao', dataAdmissao ? dataAdmissao.slice(0, 10) : '', { expires: rememberMe ? 365 : null });
      Cookies.set('funcionarioIdade', idade, { expires: rememberMe ? 365 : null });
      Cookies.set('funcionarioGenero', genero, { expires: rememberMe ? 365 : null });
      Cookies.set('funcionarioEmail', email, { expires: rememberMe ? 365 : null });
      Cookies.set('emailConfirmed', emailConfirmed, { expires: rememberMe ? 365 : null });
      console.log('EmailConfirmed set to:', emailConfirmed);
      Cookies.set('empresaId', empresaId, { expires: rememberMe ? 365 : null });
      Cookies.set('empresaNome', empresaNome, { expires: rememberMe ? 365 : null });
      Cookies.set('plano_nome', plano_nome, { expires: rememberMe ? 365 : null });
      console.log("Funcionario data updated and stored in cookies and state.");
    } catch (error) {
      console.error("Failed to fetch funcionario data:", error);
      setError("Falha ao carregar dados do colaborador.");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Login attempt with:", formData);
  
    setIsLoading(true); // Iniciar carregamento
  
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/login`, formData, {
        headers: {
          Accept: "application/json",
        },
      });
      console.log("Login response:", response);
  
      if (response.data && response.data.token && response.data.refreshToken && response.data.entityType) {
        const { token, refreshToken, entityType } = response.data;
  
        Cookies.set('token', token, { expires: rememberMe ? 365 : 1 });
        Cookies.set('refreshToken', refreshToken, { expires: rememberMe ? 365 : 7 }); // Expira em 7 dias se não for rememberMe
        Cookies.set('entityType', entityType, { expires: rememberMe ? 365 : undefined });
  
        setUserEmail(formData.email);
        console.log(`User entity type detected: ${entityType}`);
        console.log('entityType set to:', entityType);
  
        if (entityType === "empresa") {
          const empresaIdResponse = await axios.get(`${process.env.REACT_APP_API_URL}/empresaId`, {
            params: { email: formData.email },
            headers: {
              Accept: "application/json",
            },
          });
          console.log("Empresa ID fetch response:", empresaIdResponse);
          if (empresaIdResponse.data && empresaIdResponse.data.empresaId) {
            const empresaId = empresaIdResponse.data.empresaId;
            Cookies.set("empresaId", empresaId, { expires: rememberMe ? 365 : undefined });
            console.log('empresaId set to:', empresaId);
  
            if (formData.email === EMAIL_ESPECIAL) {
              setUser("empresa");
              Cookies.set('user', 'empresa', { expires: rememberMe ? 365 : undefined });
              navigate(DASHBOARD_ESPECIAL);
              console.log("Navigated to Special Dashboard for specific email.");
            } else {
              setUser("empresa");
              Cookies.set('user', 'empresa', { expires: rememberMe ? 365 : undefined });
  
              if (redirectUrl && redirectUrl.includes('/escolherplano')) {
                navigate(redirectUrl);
                console.log("Redirecting to Escolher Plano page.");
              } else {
                const plano_nome = Cookies.get('plano_nome');
                console.log('Valor do cookie plano_nome:', plano_nome);
                navigate("/DashboardEmpresa");
                console.log("Navigated to Empresa Dashboard.");
              }
            }
          } else {
            console.error("Empresa ID not found in the response.");
            setError("ID da empresa não encontrado.");
          }
        } else if (entityType === "funcionario") {
          await fetchAndStoreFuncionarioData(formData.email);
          setUser("funcionario");
          Cookies.set('user', 'funcionario', { expires: rememberMe ? 365 : undefined });
          const plano_nome = Cookies.get('plano_nome');
          console.log('Valor do cookie plano_nome:', plano_nome);
          navigate("/DashboardColab");
          console.log("Navigated to Funcionario Dashboard.");
        }
      } else {
        console.error("Invalid login response data.");
        setError("Dados de resposta de login inválidos.");
      }
    } catch (error) {
      console.error("Login failed:", error);
      setError("Credenciais inválidas.");
    } finally {
      setIsLoading(false); // Finalizar carregamento
    }
  };
  

  return (
    <div className="centerflex wh100 grafite-bg">
      <div className="cxlogin">
        <div className="h30" >
          <img className="w50 pd15" src={logoblack} alt="Logo Black" />
        </div>

        {isLoading ? (
          <div className="loading-container2">
            <div className="loading-spinner2"></div>
          </div>
        ) : (

          <form className="centerflex2 h70" onSubmit={handleSubmit}>
            <h3 className='mb15' >Fazer Login</h3>
            {error && <p className="error-message">{error}</p>}
            <input
              type="text"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="E-mail"
              required
            />
            <input
              type="password"
              id="senha"
              name="senha"
              value={formData.senha}
              onChange={handleChange}
              placeholder="Senha"
              required
            />
            <button type="submit" className="botao1">
              Login
            </button>
            <div className="checkbox-container">
              <input
                type="checkbox"
                id="rememberMe"
                name="rememberMe"
                checked={rememberMe}
                onChange={handleCheckboxChange}
                className="custom-switch"
              />
              <label className="label1" htmlFor="rememberMe">Manter-me logado</label>
            </div>
            <div className="flexrow">
              <Link className="cinza f12 " to="/CadastrarEmpresa">
                Cadastro Empresa
              </Link>

              <Link className="cinza f12" to="/CadastrarColabComCodigo">
                Cadastro Colaborador
              </Link>
            </div>
            <Link className="cinza f12" to="/RecuperacaoSenha">
              Esqueci Minha Senha
            </Link>
          </form>
        )}
      </div>
    </div>
  );

};

export default Home;