import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import "../css/ea.css";
import more from "../assets/img/more.svg";
import removeicon from "../assets/img/remove.svg";
import Cookies from 'js-cookie';
import axios from 'axios';
import imageCompression from 'browser-image-compression';




const CadastrarProduto = () => {
  const [formData, setFormData] = useState({
    nome: "",
    valor_ups: "",
    quantidade: "",
  });
  const [fotoProduto, setFotoProduto] = useState(null);
  const [cadastroSucesso, setCadastroSucesso] = useState(false);
  const [mostrarFormulario, setMostrarFormulario] = useState(true); // Novo estado
  const token = Cookies.get('token');

  const onDrop = async (acceptedFiles) => {
    // Configurações para a compressão e conversão para .webp
    const options = {
      useWebWorker: true,
      fileType: 'image/webp', // Converte para .webp
    };
  
    // Tenta converter a imagem
    try {
      const compressedFile = await imageCompression(acceptedFiles[0], options);
      setFotoProduto(compressedFile); // Define a imagem convertida no estado
    } catch (error) {
      console.error("Erro ao converter imagem:", error);
    }
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Verifica se o formulário tem os dados necessários
    if (!formData.nome || !formData.valor_ups || !formData.quantidade) {
      console.log("Preencha todos os campos.");
      return;
    }

    // Cria um objeto FormData para enviar os dados
    const formDataToSend = new FormData();
    formDataToSend.append("nome", formData.nome);
    formDataToSend.append("valor_ups", formData.valor_ups);
    formDataToSend.append("quantidade", formData.quantidade);
    formDataToSend.append("empresaId", Cookies.get("empresaId")); // Pega o ID da empresa dos cookies

    // Adiciona a imagem se estiver presente
    if (fotoProduto) {
      formDataToSend.append("imagem", fotoProduto); // Envia a imagem diretamente
      sendFormData(formDataToSend);
    } else {
      sendFormData(formDataToSend); // Envia os dados sem imagem
    }
  };

  const [errorMessage, setErrorMessage] = useState("");

  const sendFormData = async (formDataToSend) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/novoproduto`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': `Bearer ${token}`,
          },
        }
      );

      console.log("Produto cadastrado com sucesso:", response.data);
      setCadastroSucesso(true);
      setFormData({ nome: "", valor_ups: "", quantidade: "" });
      setFotoProduto(null);
    } catch (error) {
      console.error("Erro ao cadastrar produto:", error);
      setErrorMessage("Erro ao cadastrar produto. Por favor, tente novamente.");
    }
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  if (cadastroSucesso) {
    return (
      <div className="flexcolumn">
        <div className="tandline">
          <h2>Cadastrar Produto</h2>
          <div className="line300"></div>
        </div>
        <span className="stext legenda">Produto cadastrado com sucesso!</span>
        <button className="btn btn-primary w_fit" onClick={() => setCadastroSucesso(false)}>
          Cadastrar Outro
        </button>
      </div>
    );
  }

  return (
    <div className="">
      <div className="flexcolumn">
        <div className="tandline">
          <h2>Cadastrar Produto</h2>
          <div className="line300"></div>
        </div>


        {mostrarFormulario && (

          <>
            {errorMessage && (
              <div className="error-message">
                {errorMessage}
              </div>
            )}


            <form className="flexcolumn" onSubmit={handleSubmit} encType="multipart/form-data">

              <div className="mb15">
                {!fotoProduto && (
                  <div {...getRootProps()} className=" custom-file-button">
                    <input {...getInputProps()} />
                    <img className="custom-file-icon" src={more} alt="Selecionar Imagem" />
                  </div>
                )}

                {fotoProduto && (
                  <div className="flexcolumn">
                    <div className="flexrow">
                      <div className="perfil-avatar">
                        <img src={URL.createObjectURL(fotoProduto)} alt="Pré-visualização do Produto" width={200} height={200} />
                      </div>
                      <div>
                        <div className="btn-red" onClick={() => setFotoProduto(null)}>
                          <img className="wid12" src={removeicon} alt="Remover Foto do Produto" />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="form-field">
                <label htmlFor="nome">Nome do Produto:</label>
                <input
                  type="text"
                  id="nome"
                  name="nome"
                  value={formData.nome}
                  onChange={(e) => setFormData({ ...formData, nome: e.target.value })}
                  required
                />
              </div>

             <div className="flexrow" > <div className="form-field">
                <label htmlFor="valor_ups">Valor em UPS:</label>
                <input
                  type="number"
                  id="valor_ups"
                  name="valor_ups"
                  value={formData.valor_ups}
                  onChange={(e) => setFormData({ ...formData, valor_ups: e.target.value })}
                  required
                />
              </div>

              <div className="form-field">
                <label htmlFor="quantidade">Quantidade:</label>
                <input
                  type="number"
                  id="quantidade"
                  name="quantidade"
                  value={formData.quantidade}
                  onChange={(e) => setFormData({ ...formData, quantidade: e.target.value })}
                  required
                />
              </div>
              </div>

              <div className="form-submit">
                <button type="submit" className="btn-black">
                  <span>Salvar</span>
                </button>
              </div>
            </form>
          </>
        )}
      </div>
    </div>
  );

};

export default CadastrarProduto;
