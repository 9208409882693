import React, { useState, useEffect, useRef } from "react";

import axios from "axios";
import { useDropzone } from "react-dropzone";
import AvatarEditor from "react-avatar-editor";
import Cookies from 'js-cookie';
import more from "../assets/img/more.svg";
import deleteIcon from "../assets/img/more.svg";  // Importa o ícone de deletar
import noprofile from "../assets/img/blankprofile.webp";
import ConfirmEmail from "../CofirmEmail";
import useLogout from '../useLogout';

const AjustesE = () => {
  const [empresaData, setEmpresaData] = useState({
    nome: Cookies.get('empresaNome') || "",
    endereco: Cookies.get('empresaEndereco') || "",
    adm_nome: Cookies.get('empresaadm_nome') || "",
    telefone: Cookies.get('empresaTelefone') || "",
    email: Cookies.get('empresaEmail') || "",
  });
  const [logo, setLogo] = useState(Cookies.get('empresaLogo') || null);
  const [showConfirmEmail, setShowConfirmEmail] = useState(false);
  const handleLogout = useLogout();

  const [novoLogo, setNovoLogo] = useState(null);
  const [zoom, setZoom] = useState(1);
  const editorRef = useRef(null);
  const [erroMsg, setErroMsg] = useState("");
  const [atualizacaoSucesso, setAtualizacaoSucesso] = useState(false);
  const [imageKey, setImageKey] = useState(Date.now());  // Adiciona o estado imageKey para controlar o cache da imagem

  const empresaId = Cookies.get("empresaId");

  const onDrop = (acceptedFiles) => {
    setNovoLogo(acceptedFiles[0]);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEmpresaData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleZoomChange = (e) => {
    setZoom(parseFloat(e.target.value));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    Object.keys(empresaData).forEach(key => {
      formDataToSend.append(key, empresaData[key]);
    });

    if (novoLogo) {
      const canvas = editorRef.current.getImageScaledToCanvas();
      canvas.toBlob((blob) => {
        formDataToSend.append("logo", blob, "empresa-logo.webp");
        sendUpdate(formDataToSend);
      }, 'image/webp');
    } else {
      sendUpdate(formDataToSend);
    }
  };

  const fieldLabels = {
    nome: "Razão Social",
    endereco: "Endereço",
    adm_nome: "Nome do Administrador",
    telefone: "Telefone",
    email: "Email",
  };


  useEffect(() => {



    const emailConfirmed = Cookies.get('empresaemailConfirmed');
    console.log('Valor do cookie empresaemailConfirmed:', emailConfirmed);
    setShowConfirmEmail(emailConfirmed === 'false');
  })

  const sendUpdate = async (formDataToSend) => {
    try {
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/empresa/${empresaId}`, formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log("Empresa atualizada com sucesso:", response.data);

      // Atualizar cookies com os novos dados
      Cookies.set('empresaNome', empresaData.nome);
      Cookies.set('empresaEndereco', empresaData.endereco);
      Cookies.set('empresaadm_nome', empresaData.adm_nome);
      Cookies.set('empresaTelefone', empresaData.telefone);
      Cookies.set('empresaEmail', empresaData.email);
      if (response.data.logo) {
        Cookies.set('empresaLogo', response.data.logo);
        setLogo(response.data.logo);
        setImageKey(Date.now());  // Atualiza o imageKey para modificar o URL e evitar cache
      }

      // Atualizar o estado local com os novos dados
      setEmpresaData(prev => ({
        ...prev,
        nome: empresaData.nome,
        endereco: empresaData.endereco,
        adm_nome: empresaData.adm_nome,
        telefone: empresaData.telefone,
        email: empresaData.email,
      }));

      setAtualizacaoSucesso(true);
      setErroMsg(''); // Limpa mensagens de erro anteriores

    } catch (error) {
      console.error("Erro ao atualizar dados da empresa:", error);
      setErroMsg("Erro ao atualizar dados. Por favor, tente novamente mais tarde.");
    }
  };

  const handleRemoveLogo = async () => {
    try {
      console.log("Iniciando remoção do logo da empresa");
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/empresa/${empresaId}/remover-logo`, null, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      console.log("Resposta da API:", response.data);

      // Update application state based on successful response
      // ... (your existing logic here) ...

    } catch (error) {
      console.error("Erro ao remover logo:", error);
      setErroMsg("Erro ao remover logo. Por favor, tente novamente mais tarde.");
    } finally {
      console.log("Finalizando remoção do logo da empresa");
    }
  };

  if (atualizacaoSucesso) {
    return (
      <div>
        <h2>Atualização feita com sucesso!</h2>
        <button className="btn" onClick={() => window.location.reload()}>Recarregar</button>
      </div>
    );
  }

  return (
    <div>
      <div className="flexcolumn">
        <div className="tandline">
          <h2>Ajustes da Empresa</h2>
          <div className="line300"></div>
        </div>

        <form onSubmit={handleSubmit} encType="multipart/form-data">
          <div className="flexrow mb15">
            {novoLogo ? (
              <AvatarEditor
                ref={editorRef}
                image={typeof novoLogo === "string" ? novoLogo : URL.createObjectURL(novoLogo)}
                width={250}
                height={250}
                border={25}
                borderRadius={125}
                color={[255, 255, 255, 255]}
                scale={zoom}
                rotate={0}
                backgroundColor={"transparent"}
              />
            ) : (
              <div className="logo-preview w30">
                <img className="border100 w100" src={logo ? `https://painel.rise.app.br/${logo}` : noprofile} alt="Foto de Perfil Atual" />
              </div>

            )}



            <div {...getRootProps()} className="dropzone">
              <input {...getInputProps()} />
              <img className="custom-file-icon" src={more} alt="Adicionar Logo" />
            </div>




          </div>

          <div className="form-fields-container">
            {Object.entries(empresaData).map(([key, value]) => (
              <div className="form-field" key={key}>
                <label>{fieldLabels[key] || key.charAt(0).toUpperCase() + key.slice(1)}:</label>
                <input type="text" name={key} value={value} onChange={handleChange} required />
              </div>
            ))}
          </div>
          {erroMsg && <div className="error-msg">{erroMsg}</div>}

          <div className="flexrow_mo" >
            <button type="submit" className="btn auto_10_top m10top">Atualizar</button>
            <button type="submit" className="btn auto_10_top m10top d_none" onClick={handleLogout}>Sair</button>
          </div>
        </form>

        <div className="d_none" >

          {showConfirmEmail && <ConfirmEmail />}
        </div>


      </div>
    </div>
  );
};

export default AjustesE;
