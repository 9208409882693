import React, { useState, useEffect } from "react";
import axios from 'axios';
import "../css/ea.css";
import noprofile from "../assets/img/blankprofile.webp";
import ListaProdutos from "./ListaProdutos";

import Cookies from "js-cookie";
import CadastrarColab from "./CadastrarColab";
import PerfilCompletoColab from "./PerfilCompletoColab";
import CadastrarProduto from "./CadastrarProduto";
import Trocas from "./HistoricoTrocas";
import GerenciarTrocas from "./GerenciarTrocas";

const Produtos = () => {
  const [selectedComponent, setSelectedComponent] = useState(null);
  const [selectedButton, setSelectedButton] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState(null);

  const user = Cookies.get('user');
  const plano_nome = Cookies.get('plano_nome');
  console.log("Valor do cookie plano_nome:", plano_nome);
  const f_Enterprise = ['enterprise_anual', 'enterprise_semestral'].includes(plano_nome);

  useEffect(() => {
    setSelectedComponent("ListaProdutos");
    setSelectedButton("ListaProdutos");
  }, []);

  const handleButtonClick = (componentName) => {
    setSelectedComponent(componentName);
    setSelectedButton(componentName);
  };

  const handleVerMais = (colaborador) => {
    setPopupContent(colaborador);
    setShowPopup(true);
  };

  const renderSelectedComponent = () => {
    if (!f_Enterprise) {
      if (user === 'funcionario') {
        // Mensagem para funcionários
    
        return <p className="alert-text">A empresa precisa fazer upgrade do plano para que a loja fique disponível.</p>;
      } else if (user === 'empresa') {
        // Mensagem para empresas
        return <p className="alert-text">Atualize o plano para usar a loja e gerenciar trocas.</p>;
       
      }
    }
  

    switch (selectedComponent) {
      case "ListaProdutos":
        return <ListaProdutos />;
      case "CadastrarProduto":
        return <CadastrarProduto />;
      case "Trocas":
        return <Trocas />;
      case "GerenciarTrocas":
        return <GerenciarTrocas />;
      default:
        return null;
    }
  };

  return (
    <div className="w100">
      <div className="  column_mo direitaopcs">
        <div className="ranking-conteudos">
          {renderSelectedComponent()}
        </div>

        <div className="flexcolumn fix_b_centro flexrow_mo m10_bt ">
          <div
            className={`btn ${selectedButton === "ListaProdutos" ? "selected" : ""}`}
            onClick={() => handleButtonClick("ListaProdutos")}
          >
            <span>Produtos</span>
          </div>

          {f_Enterprise && user === 'empresa' && (
            <div
              className={`btn ${selectedButton === "CadastrarProduto" ? "selected" : ""}`}
              onClick={() => handleButtonClick("CadastrarProduto")}
            >
              <span>Cadastrar Novo</span>
            </div>
          )}

          {f_Enterprise && user === 'empresa' && (
            <div
              className={`btn ${selectedButton === "GerenciarTrocas" ? "selected" : ""}`}
              onClick={() => handleButtonClick("GerenciarTrocas")}
            >
              <span>Gerenciar Trocas</span>
            </div>
          )}

          {user === 'funcionario' && (
            <div
              className={`btn ${selectedButton === "Trocas" ? "selected" : ""}`}
              onClick={() => handleButtonClick("Trocas")}
            >
              <span>Minhas Trocas</span>
            </div>
          )}
        </div>
      </div>

      {showPopup && (
        <PerfilCompletoColab
          colaborador={popupContent}
          onClose={() => setShowPopup(false)}
        />
      )}
    </div>
  );
};

export default Produtos;
