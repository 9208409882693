import React, { useState, useEffect } from "react";
import "./css/ea.css";
import PerfilRankingMin from "./components/PerfilRankingMin";
import Colaboradores from "./components/Colaboradores";
import Produtos from "./components/Produtos";
import NovoPost from "./components/NovoPost";
import ListaPosts from "./components/ListaPosts";
import Ajustes from "./components/AjustesE";
import noprofile from "./assets/img/blankprofile.webp";
import axios from "axios";
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import sair from "./assets/img/sair.svg";
import useLogout from './useLogout';
import ConfirmEmail from "./CofirmEmail";
import { ReactComponent as NewIcon } from './assets/img/mais.svg';
import { ReactComponent as SettingsIcon } from './assets/img/settings.svg';
import { ReactComponent as ColabIcon } from './assets/img/colaboradores.svg';
import { ReactComponent as InicioIcon } from './assets/img/inicio.svg';
import { ReactComponent as StoreIcon } from './assets/img/store.svg';
import Suporte from "./Suporte";
import verificarPlano from './VerificarPlano';
import Atualizar from './Atualizar';
import Ranking from "./components/Ranking";
import useTokenManager from './TokenManager'; 




const DashboardEmpresa = () => {
  const token = useTokenManager();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [showConfirmEmail, setShowConfirmEmail] = useState(false);
  const [colaboradores, setColaboradores] = useState([]); // Estado para armazenar os colaboradores
  const [selectedComponent, setSelectedComponent] = useState(null);
  const [selectedButton, setSelectedButton] = useState(null);
  const [empresaData, setEmpresaData] = useState(null); // Estado para armazenar os dados da empresa
  const [loading, setLoading] = useState(true);
  const handleLogout = useLogout();
  const navigate = useNavigate(); // Mova isso para fora da função handleLogout

  useEffect(() => {
    
    const verificarCookies = async () => {
      // Verificar se o empresaId está presente no cookie
      const empresaId = Cookies.get('empresaId');
  
      if (!empresaId) {
        // Se não houver empresaId no cookie, desloga o usuário
        handleLogout();
      } else {
        // Primeiro, buscar e armazenar os dados da empresa
        await Atualizar().fetchAndStoreEmpresaData(empresaId);
  
        // Depois, continua com as outras verificações
        await verificarPlano(empresaId);
        Atualizar().atualizarContagemPosts(empresaId);
  
        // Aqui, já podemos verificar o estado de confirmação do email
        const emailConfirmed = Cookies.get('empresaemailConfirmed');
        console.log('Valor do cookie empresaemailConfirmed após atualização:', emailConfirmed);
        setShowConfirmEmail(emailConfirmed === 'false');
      }
    };
  
    verificarCookies(); // Chame a função assíncrona dentro do useEffect
  }, [navigate]);
  


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {





    setSelectedComponent("ListaPosts");
    setSelectedButton("ListaPosts");

    // Função para buscar os dados do perfil da empresa após o login
    const fetchEmpresaData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/empresa/${Cookies.get('empresaId')}`
        );
        setEmpresaData(response.data);
      } catch (error) {
        console.error("Erro ao obter dados do perfil da empresa:", error);
      }
    };



    // Função para buscar os colaboradores ordenados por ranking da empresa
    const fetchRanking = async () => {
      try {
        const token = Cookies.get("token"); // Verifica o token de autenticação
        console.log("Token obtido dos cookies:", token); // Log do token
        
        const empresaId = Cookies.get("empresaId"); // Obtenha o ID da empresa dos cookies

        if (!token) {
          throw new Error("Usuário não autenticado");

        }

        if (!empresaId) {
          throw new Error("ID da empresa não especificado");
        }

        console.log("Token obtido dos cookies:", token);
        console.log("ID da empresa obtido dos cookies:", empresaId);

        const response = await axios.get(`${process.env.REACT_APP_API_URL}/rankingColaboradores`, {
          headers: {
            'Authorization': `Bearer ${token}`
          },
          params: {
            empresaId: empresaId
          }
        });
        setColaboradores(response.data);
      } catch (error) {
        console.error("Erro ao buscar ranking de colaboradores:", error);
      }
    };







    // Chame as funções para buscar os dados do perfil da empresa e o ranking dos colaboradores
    Promise.all([fetchEmpresaData(), fetchRanking()])
      .then(() => setLoading(false)) // Defina o estado de carregamento para false quando os dados forem carregados
      .catch(error => console.error("Erro ao buscar dados:", error));
  }, []);

 

  const handleButtonClick = (componentName) => {
    setSelectedComponent(componentName);
    setSelectedButton(componentName);
  };

  return (
    <div className="mainfc">
      <Suporte />
      {showConfirmEmail && !isMobile && <ConfirmEmail />}
      {loading && (
        // Renderiza a animação de carregamento ocupando toda a tela
        <div className="loading-container2">
          <div className="loading-spinner"></div>
        </div>
      )}

      {!loading && (
        <div className="esquerda">
          <div className="prevprofile">
            <div className="flexcolumn">
              <div className="flexrow fixed_top">
                <div className="fotoperfil">
                  {/* Verifique se os dados da empresa estão disponíveis e se a URL do logo não está vazia */}
                  {empresaData ? (
                    <img
                      className="w100p border100"
                      src={empresaData.logo ? `https://painel.rise.app.br/${empresaData.logo}` : noprofile}
                      alt="Imagem de Perfil"
                    />
                  ) : (
                    <img
                      className="w100p border100"
                      src={noprofile}
                      alt="Sem imagem de perfil"
                    />
                  )}
                </div>

                <div className="w74 dadosperfil">
                  {empresaData && (
                    <>
                      <span className="fontnome">{empresaData.nome}</span>
                      <span className="white_f"><strong>Código de Cadastro: </strong>{empresaData.cadastroId}</span>
                      <span className="white_f"><strong>Colaboradores: </strong>{empresaData.numColaboradores} de {empresaData.colaboradoresLimite}</span>

                      {(() => {
                        const plano_nome = Cookies.get('plano_nome');

                        if (plano_nome === 'experience') {
                          return <span className="white_f">
                            <strong>Plano:</strong> Experience
                            <button onClick={() => navigate("/EscolherPlano")} className="btn_pink">
                              Upgrade
                            </button>
                          </span>;
                        } else if (plano_nome === 'expert_semestral' || plano_nome === 'expert_anual') {
                          return (
                            <span className="white_f">
                              <strong>Plano:</strong> Expert
                              <button onClick={() => navigate("/EscolherPlano")} className="btn_pink">
                                Upgrade
                              </button>
                            </span>
                          );
                        } else if (plano_nome === 'enterprise_anual' || plano_nome === 'enterprise_semestral') {
                          return (
                            <span className="white_f">
                              <strong>Plano:</strong> Enterprise

                            </span>
                          );
                        } else {
                          return <span className="white_f"><strong>Plano:</strong> Desconhecido</span>;
                        }
                      })()}
                    </>
                  )}
                </div>




              </div>

              <div className="btns-perfil">


                <div
                  className={`btn2  ${selectedButton === "ListaPosts" ? "selected" : ""}`}
                  onClick={() => handleButtonClick("ListaPosts")}
                >
                  <InicioIcon className="icon" />
                  <span className="icon_text">Inicio</span>
                </div>

                <div className="flexrow f_basic">



                  <div
                    className={`btn2 w70 w_m100 ${selectedButton === "Ajustes" ? "selected" : ""}`}
                    onClick={() => handleButtonClick("Ajustes")}
                  >
                    <SettingsIcon className="icon" />
                    <span className="icon_text" >Ajustes</span>
                  </div>

                  <div className="btn2 w30 mnone" onClick={handleLogout}>
                    <img src={sair} className="icon" />
                    <span className="icon_text" >Sair</span>
                  </div>

                </div>



                <div
                  className={`btn2  ${selectedButton === "Colaboradores" ? "selected" : ""}`}
                  onClick={() => handleButtonClick("Colaboradores")}
                >
                  <ColabIcon className="icon" />
                  <span className="icon_text" >Colaboradores</span>
                </div>
                <div
                  className={`btn2  ${selectedButton === "Produtos" ? "selected" : ""}`}
                  onClick={() => handleButtonClick("Produtos")}
                >
                  <StoreIcon className="icon" />
                  <span className="icon_text" >Loja</span>
                </div>



                <div
                  className={`btn2  ${selectedButton === "NovoPost" ? "selected" : ""}`}
                  onClick={() => handleButtonClick("NovoPost")}
                >
                  <NewIcon className="icon" />
                  <span className="icon_text" >Novo Post</span>
                </div>

              </div>

            </div>
          </div>

          <div className="recentes">

            <Ranking colaboradores={colaboradores.slice(0, 6)} /> {/* Passando o prop */}
          </div>


        </div>
      )}
      <div className="linhadiv mnone"></div>

      <div className="direita">
        {selectedComponent === "Colaboradores" && <Colaboradores />}
        {selectedComponent === "Produtos" && <Produtos />}
        {selectedComponent === "NovoPost" && <NovoPost />}
        {selectedComponent === "ListaPosts" && <ListaPosts />}
        {selectedComponent === "Ajustes" && <Ajustes />}
      </div>
    </div>
  );
};

export default DashboardEmpresa;