import { useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode'; // Certifique-se de que o jwtDecode está corretamente importado.

const useTokenManager = () => {
  const [token, setToken] = useState(null);

  useEffect(() => {
    const atualizarToken = async () => {
      const refreshToken = Cookies.get("refreshToken");

      if (refreshToken) {
        try {
          // Solicita um novo token usando o refresh token
          const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/refresh-token`, { token: refreshToken });

          if (response.data && response.data.newToken) {
            Cookies.set("token", response.data.newToken, { expires: 1 }); // Atualiza o token nos cookies
            setToken(response.data.newToken); // Armazena o novo token no estado
            console.log("Token atualizado com sucesso.");
          } else {
            console.error("Falha ao atualizar o token.");
            setToken(null);
          }
        } catch (error) {
          console.error("Erro ao atualizar o token:", error);
          setToken(null);
        }
      } else {
        console.error("Nenhum refresh token disponível.");
        setToken(null);
      }
    };

    atualizarToken();
  }, []); // O useEffect será executado toda vez que o componente montar

  return token;
};

export default useTokenManager;
